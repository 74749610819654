import { Suspense } from "react";
import { NavLink, Outlet } from "react-router-dom";

export const SharedLayout = ({
  website,
  setWebsite,
  openSelect,
  setOpenSelect,
}) => {
  return (
    <>
      <header className="header">
        <ul className="header__list">
          <li className="header__item">
            <NavLink to="/" className="header__item--link">
              Lead
            </NavLink>
          </li>
          <li className="header__item">
            <NavLink to="/click" className="header__item--link">
              Click
            </NavLink>
          </li>
          <li className="header__item">
            <NavLink to="/reg" className="header__item--link">
              Reg
            </NavLink>
          </li>
          <li className="header__item">
            <NavLink to="/dep" className="header__item--link">
              Dep
            </NavLink>
          </li>
          <li className="header__item">
            <NavLink to="/addOffer" className="header__item--link">
              Add Offer
            </NavLink>
          </li>
          <li className="header__item">
            <NavLink to="/download" className="header__item--link">
              Download
            </NavLink>
          </li>
        </ul>
        <div
          className="header__item"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            width: "140px",
            marginLeft: "10px",
            color: "#ff267d",
            fontSize: "24px",
            fontWeight: "700",
          }}
          id="select-header"
          onClick={(e) => {
            if (
              e.target.id === "select-header" ||
              e.target.id === "select-svg" ||
              e.target.id === "select-path"
            ) {
              setOpenSelect(true);
            }
          }}
        >
          {window.location.hostname === "alrety.top"
            ? website === 1
              ? "spinua"
              : website === 2
              ? "freespin"
              : website === 3
              ? "superspin"
              : "luckyslot"
            : website === 5
            ? "megaslot"
            : website === 6
            ? "goldspiin"
            : "goldrushua"}
          <svg
            width="16"
            height="10"
            viewBox="0 0 16 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id="select-svg"
          >
            <path
              id="select-path"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.7531 0.439333C15.3388 1.02512 15.3388 1.97487 14.7531 2.56065L8 9.31371L1.24695 2.56065C0.66116 1.97487 0.66116 1.02512 1.24695 0.439333C1.83273 -0.146453 2.78248 -0.146453 3.36827 0.439333L8 5.07107L12.6317 0.439333C13.2175 -0.146453 14.1673 -0.146453 14.7531 0.439333Z"
              fill="#ffffff"
            />
          </svg>
          {openSelect && (
            <div className="select__header--modal">
              {window.location.hostname === "alrety.top" ? (
                <>
                  <p
                    className="select__header--item"
                    onClick={() => {
                      setWebsite(1);
                      setOpenSelect(false);
                    }}
                  >
                    spinua
                  </p>
                  <p
                    className="select__header--item"
                    onClick={() => {
                      setWebsite(2);
                      setOpenSelect(false);
                    }}
                  >
                    freespin
                  </p>
                  <p
                    className="select__header--item"
                    onClick={() => {
                      setWebsite(3);
                      setOpenSelect(false);
                    }}
                  >
                    superspin
                  </p>
                  <p
                    className="select__header--item"
                    onClick={() => {
                      setWebsite(4);
                      setOpenSelect(false);
                    }}
                  >
                    luckyslot
                  </p>
                </>
              ) : (
                <>
                  <p
                    className="select__header--item"
                    onClick={() => {
                      setWebsite(5);
                      setOpenSelect(false);
                    }}
                  >
                    megaslot
                  </p>
                  <p
                    className="select__header--item"
                    onClick={() => {
                      setWebsite(6);
                      setOpenSelect(false);
                    }}
                  >
                    goldspiin
                  </p>
                  <p
                    className="select__header--item"
                    onClick={() => {
                      setWebsite(7);
                      setOpenSelect(false);
                    }}
                  >
                    goldrushua
                  </p>
                </>
              )}
            </div>
          )}
        </div>
      </header>
      <Suspense fallback={<div>Loading...</div>}>
        <Outlet />
      </Suspense>
    </>
  );
};
